<style lang="less">
.c_course_study_detail {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #ededed;
  .image-item {
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c0c4cc;
    .avatar {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .image-none {
        width: 180px;
        height: 120px;
      }
    }
  }

  .ccsd-header {
    padding: 10px;
    margin-left: 20px;
    background: #f6fbff;
    p {
      display: flex;
      align-items: center;
    }
    .ccsd-header-courseTitle {
      > span:first-child {
        font-size: 20px;
        font-weight: 500;
        margin-right: 10px;
      }
    }
    .mgtopbottom10 {
      margin: 10px 0;
    }
    .ccsd-header-info {
      span {
        color: #5c5d60;
        display: inline-flex;
        align-items: center;
        i {
          color: @mainColor;
          font-size: 20px;
          margin-right: 5px;
        }
        img {
          width: 100%;
          max-width: 250px;
        }
      }
      .mgr15 {
        margin-right: 15px;
      }
      font-size: 14px;
    }
  }
  .ccsd-study-btn {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;

    .ccsd-study-progress {
      width: 30%;
      min-width: 200px;
      margin-left: 20px;
      p:first-child {
        font-size: 13px;
        margin-bottom: 5px;
      }
      .ccsd-s-progress-unit {
        color: @mainColor;
      }
    }
  }
}
</style>
<template>
  <el-row class="c_course_study_detail" type="flex" align="top">
    <el-col :span="6" class="image-item" v-if="data">
      <el-avatar shape="square" class="avatar" :src="data.coverUrl">
        <img class="image-none" src="../../../../../assets/logo_top.png" />
      </el-avatar>
    </el-col>

    <el-col :span="18">
      <div class="ccsd-header">
        <p class="ccsd-header-courseTitle">
          <span>{{ data ? data.courseName : "--" }}</span>
          <el-tag size="small">第{{ data ? data.semester : "-" }}学期</el-tag>
        </p>
        <p class="ccsd-header-info mgtopbottom10">
          <span class="mgr15">
            <i class="el-icon-s-custom"></i> 授课老师：{{
              data ? data.teacher : "--"
            }}
          </span>
          <span
            ><i class="el-icon-time"></i>课程时长：{{
              data ? data.courseDuration : "0"
            }}（小时）</span
          >
        </p>
        <p class="ccsd-header-info">
          <span
            >课程简介：<span v-html="data ? data.courseDesc : '--'"></span
          ></span>
        </p>
      </div>
      <div class="ccsd-study-btn">
        <el-button type="primary" icon="el-icon-d-arrow-right" @click="study">
          继续学习
        </el-button>
        <div class="ccsd-study-progress">
          <p>
            学习进度：<span class="ccsd-s-progress-unit"
              >{{ data ? data.learnProgress : 0 }}%</span
            >
          </p>
          <el-progress
            :show-text="false"
            :percentage="data ? data.learnProgress : 0"
          ></el-progress>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { KeepStuding } from "@/libs/api/course";
export default {
  name: "c_course_study_detail",
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  computed: {
    course() {
      return this.$store.getters.getStuMyCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    study() {
      let routeData = this.$router.resolve({
        name: "cours-student-study-details",
        query: {
          courseId: this.course.id,
          studentId: this.user.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>