
<style scoped lang="less">
.c_course_study_directory {
  min-height: 370px;
  max-height: 370px;
  overflow-y: auto;
  .chapter {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #eee;
    .chapter_hedaer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      .chapter_name {
        font-size: 18px;
        padding-left: 10px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 25%;
          height: 13px;
          width: 3px;
          border-radius: 2px;
          background: @mainColor;
        }
      }
      .chapter_percent {
        .el-icon-time {
          margin-right: 5px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.c_course_study_directory {
  .chapter {
    .chapter_collapse {
      border-top: 0;
      .el-collapse-item {
        // margin-top: 20px;
        .el-collapse-item__content {
          padding-bottom: 10px;
        }
      }
      .el-collapse-item__wrap {
        border-bottom: 0;
      }
      .el-collapse-item__header {
        background: #f1f6ff;
        border-bottom: 0;
        position: relative;
        .el-collapse-item__arrow {
          position: absolute;
          left: 10px;
          top: 18px;
        }
        .collapse_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-left: 35px;
          font-size: 16px;
          .collapse_header_progress {
            width: 200px;
          }
        }
      }

      .collapse_content {
        .collapse_content_ul {
          padding: 0 30px;
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding: 15px 0;
            .collapse_content_li_progress {
              width: 100px;
            }
            .collapse_content_li_name {
              display: inline-block;
              margin-left: 10px;
              color: #717883;
              cursor: pointer;
              &:hover {
                color: @mainColor;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="c_course_study_directory">
    <div class="chapter" v-for="(item, index) in data" :key="index">
      <div class="chapter_hedaer">
        <p class="chapter_name">{{ item.chapterName }}</p>
        <p class="chapter_percent">
          <i class="el-icon-time"></i>
          <span>学习进度：{{ item.learnProgress }}%</span>
        </p>
      </div>
      <el-collapse
        v-model="item.activeName"
        @change="handleChange"
        class="chapter_collapse"
      >
        <el-collapse-item :name="i" v-for="(c, i) in item.childList" :key="i">
          <template slot="title">
            <div class="collapse_header">
              <p>{{ c.chapterName }}</p>
              <p class="collapse_header_progress">
                <el-progress :percentage="c.learnProgress"></el-progress>
              </p>
            </div>
          </template>
          <div class="collapse_content">
            <ul class="collapse_content_ul">
              <li v-for="(d, d_i) in c.childList" :key="d_i">
                <p>
                  <el-tag type="plain" size="small">{{
                    d.resourceType
                  }}</el-tag>
                  <span
                    class="collapse_content_li_name"
                    @click="openCourse(d)"
                    >{{ d.chapterName }}</span
                  >
                </p>
                <p class="collapse_content_li_progress">
                  <el-progress
                    :percentage="d.learnProgress"
                  ></el-progress>
                </p>
              </li>
            </ul>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import result from "../../../../../libs/explorer";
export default {
  name: "c_course_study_directory",
  props: {
    data: Array,
  },
  data() {
    return {
      activeNames: "1",
      result: null,
    };
  },

  computed: {
    course() {
      return this.$store.getters.getStuMyCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    handleChange(val) {},
    openCourse(item) {
      console.log(item);
      this.result = null;
      this.findChild(this.data, item.pchapterId);
      let findIndex = this.data.findIndex((f) => f.id === this.result.id);
      // 跳过第一位索引
      if (findIndex > 0) {
        let prev = this.data[findIndex - 1];
        if (prev.learnProgress < prev.processControl) {
          return this.$message.error("请先完成上一章节课程，方可收看本章节");
        }
      }
      let data = {
        name: "cours-student-study-details",
        query: {
          id: item.id,
          courseId: this.course.id,
          studentId: this.user.id,
        },
      };
      if (item.teachingType === 2) {
        const id = item.workSetId;
        if (!id) return this.$message.error("缺少workSetId");
        data = {
          path: "/work/student/answer/write",
          query: { id },
        };
      }

      let routeData = this.$router.resolve(data);
      window.open(routeData.href, "_blank");
    },
    findChild(list, pid) {
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        // 找当前点击的id信息
        const curr = item.childList && item.childList.find((f) => f.id === pid);
        if (curr) {
          // 未找到继续往子节点查找
          this.findChild(list, curr.pchapterId);
          break;
        } else if (pid === item.id) {
          this.result = item;
          break;
        }
      }
    },
  },
};
</script>
