<template>
  <div class="c_course_study">
    <div class="c_course_items">
      <div class="stu-module-header">
        <div class="stu-module-title">课程学习</div>
      </div>
      <Detail :data="courseInfo" />
    </div>
    <div class="c_course_items">
      <div class="stu-module-header">
        <div class="stu-module-title">课程目录</div>
      </div>
      <Directory :data="detailList" />
    </div>
  </div>
</template>

<script>
import Detail from "./module/Detail.vue";
import Directory from "./module/Directory.vue";
import { CourseStudyDetail } from "@/libs/api/course";
export default {
  name: "c_course_study",
  components: { Detail, Directory },
  data() {
    return {
      courseInfo: null,
      detailList: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    course() {
      return this.$store.getters.getStuMyCourse;
    },
  },
  watch: {
    course(n) {
      this.courseInfo = null;
      this.detailList = [];
      n && this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      CourseStudyDetail(this.course.id).then((res) => {
        if (res.data) {
          this.courseInfo = res.data.courseInfo;
          this.detailList = res.data.detailList;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.c_course_study {
  .c_course_items {
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
    box-shadow: @shadow;
  }
  .c_course_items:last-child {
    margin-bottom: 0;
  }
}
</style>