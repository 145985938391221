import request from '../request'
import qs from 'qs';

// 首页课程统计
const StudentCourseStat = () => {
    return request.get('eduStudentIndex/studentCourseStat').then(res => res.data);
}

// 我的课程 - 在修、已修课程列表查询
const QueryStudentCourseList = () => {
    return request.get('studentCourse/querystudentCourseList').then(res => res.data);
}
// 普通课程 - 课程公告
const CourseNotice = (p, data) => {
    return request.post(`stuCourseDetail/queryCourseNotice?${qs.stringify(p)}`, data).then(res => res.data)
}
// 普通课程 - 课程公告 根据id查询课程公告
const QueryCourseNoticeById = (p, data) => {
    return request.post(`stuCourseDetail/queryCourseNoticeById?${qs.stringify(p)}`, data).then(res => res.data);
}
// 普通课程 - 课程学习
const CourseStudyDetail = (id, data) => {
    return request.get(`stuCourseDetail/queryCourseStudyDetail/${id}`, data).then(res => res.data);
}
// 课程目录 - 点击视频 - 即时题答题判断
const CheckInstantQuestion = (data) => {
    return request.post(`eduInstantQuestion/checkInstantQuestion`, data).then(res => res.data);
}
// 普通课程 - 继续学习
const KeepStuding = (id) => {
    return request.get(`stuCourseDetail/keepStuding/${id}`).then(res => res.data)
}
// 普通课程 - 退出学习
const QuitLearning = (data) => {
    return request.post(`stuCourseDetail/quitLearning`, data).then(res => res.data)
}
// 课程学习 - 笔记 根据id查询学生笔记详情
const SelectEduCourseOutNoteById = (p) => {
    return request.get(`eduCourseOutNote/selectEduCourseOutNoteById?${qs.stringify(p)}`).then(res => res.data)
}
// 课程学习 - 笔记 根据章节id查询学生笔记详情
const QueryEduCourseOutNoteById = (p) => {
    return request.get(`eduCourseOutNote/queryEduCourseOutNoteById?${qs.stringify(p)}`).then(res => res.data)
}
// 课程学习 - 删除笔记
const DeleteEduCourseOutNote = (data) => {
    return request.post(`eduCourseOutNote/deleteEduCourseOutNote`, data).then(res => res.data)
}
// 课程学习 - 修改笔记
const UpdateEduCourseOutNote = (data) => {
    return request.post(`eduCourseOutNote/updateEduCourseOutNote`, data).then(res => res.data)
}
// 课程学习 - 添加笔记
const InsertEduCourseOutNote = (data) => {
    return request.post(`eduCourseOutNote/insertEduCourseOutNote`, data).then(res => res.data)
}
// 学习导航
const ShowModifyCourseById = (params) => {
    return request.get(`eduCourse/showModifyCourseById?${qs.stringify(params)}`).then(res => res.data);
}
// 查询课程列表
const EduCourseList = (data) => {
    return request.post(`eduCourse/list`, data).then(res => res.data)
}
// 在线答疑 - 查询答疑
const QueryAllEduAnswering = (params) => {
    return request.post(`eduAnswering/queryAllEduAnswering?${qs.stringify(params)}`).then(res => res.data);
}
// 在线答疑 - 答疑点赞
const FabulousEduAnsweringByIdAndTea = (data) => {
    return request.post(`eduAnwFabulous/fabulousEduAnsweringByIdAndTea`, data).then(res => res.data);
}
// 在线答疑 - 答疑 - 详情
const QueryRecursionAllEduAnswering = (params) => {
    return request.get(`eduAnswering/queryRecursionAllEduAnswering?${qs.stringify(params)}`).then(res => res.data);
}
// 在线答疑 - 答疑删除
const UpdateEduAnswering = (data) => {
    return request.post(`eduAnswering/updateEduAnswering`, data).then(res => res.data);
}
//  在线答疑-发表答疑-选择课程音视频（查询）
const QueryCourseResources = (params) => {
    return request.get(`teacherCourse/queryCourseResources?${qs.stringify(params)}`).then(res => res.data);
}
// 在线答疑-发布
const AddAllEduAnswering = (data) => {
    return request.post(`eduAnswering/addAllEduAnswering`, data).then(res => res.data)
}

export {
    StudentCourseStat,
    QueryStudentCourseList,
    CourseNotice,
    QueryCourseNoticeById,
    CourseStudyDetail,
    CheckInstantQuestion,
    ShowModifyCourseById,
    EduCourseList,
    KeepStuding,
    QuitLearning,
    SelectEduCourseOutNoteById,
    QueryEduCourseOutNoteById,
    DeleteEduCourseOutNote,
    UpdateEduCourseOutNote,
    InsertEduCourseOutNote,
    QueryAllEduAnswering,
    FabulousEduAnsweringByIdAndTea,
    QueryRecursionAllEduAnswering,
    UpdateEduAnswering,
    QueryCourseResources,
    AddAllEduAnswering
}